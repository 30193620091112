import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  desktop,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile_landing.png';
import socProof_des from 'assets/images/landing/desktop_landing.png';
import socProofPreg from 'assets/images/landing/pregnant-mob.png';
import socProof_desPreg from 'assets/images/landing/pregnant-des.png';
import socProofGift from 'assets/images/landing/gift-mob.png';
import socProof_desGift from 'assets/images/landing/gift-des.png';
import Logo from 'assets/icons/logo/logo.svg';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useFirstVisitDate, useScrollToTop } from 'utils/hooks';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import Tracking from 'utils/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { updateQuizAnswers, updateQuizFlowAnswers } from 'state/user/effects';
import { resetQuizAnswers } from 'state/user/actions';
import { useLocation } from '@reach/router';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
  hideFooter?: boolean;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({
  question,
  onOptionSelect,
  hideFooter = false,
}) => {
  const { isTablet } = useQuery(); // This works in both SSR and CSR as it's not browser-specific

  // Always call useLocation, but provide default values for SSR
  const location = useLocation();
  const [questionParam, setQuestionParam] = useState<string | null>(null); // Default to null for SSR
  const quiz_flow_answers =
    useSelector((state: AppState) => state.user?.quiz_flow_answers) || [];
  const quiz_answers =
    useSelector((state: AppState) => state.user?.quiz_answers) || {};
  const dispatch = useDispatch();

  // Effect to handle client-side logic only
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get('q');
    setQuestionParam(queryParam ?? null); // Set query parameter if available
  }, [location.search]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (quiz_flow_answers.length && Object.keys(quiz_answers).length >= 3) {
        dispatch(updateQuizFlowAnswers([]));
        dispatch(resetQuizAnswers(null));
      }
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [location, quiz_flow_answers, quiz_answers, dispatch]);
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-wb';
  const isAddsFlow = location.pathname.includes('start') || quiz !== 'main-wb';

  const pickSocialProfImage = (variant?: string) => {
    if (variant === 'pregnant') {
      return {
        mob: socProofPreg,
        des: socProof_desPreg,
      };
    }
    if (variant === 'gift') {
      return {
        mob: socProofGift,
        des: socProof_desGift,
      };
    }
    return {
      mob: socProof,
      des: socProof_des,
    };
  };

  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={true}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Container hideFooter={hideFooter}>
        {!isAddsFlow && <Header isMobileApp={false} />}
        <Wrapper isFullHeight={isAddsFlow}>
          {isAddsFlow && (
            <HeaderContainer>
              <HeaderImg src={Logo} alt="Logo" />
            </HeaderContainer>
          )}

          <W1>
            <SocialProof
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
              isFullHeight={isAddsFlow}
            />
          </W1>
          <WrappedItem>
            <W3>
              <TitleContainer>
                <Title
                  type="h1"
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>
              <DescriptionContainer>
                <Label
                  type="h2"
                  dangerouslySetInnerHTML={{
                    __html: question?.description || '',
                  }}
                />
              </DescriptionContainer>
              <ButtonsTitleContainer>
                <ButtonsLabel
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                />
              </ButtonsTitleContainer>
              {renderGenderOptions()}
              {question?.bfLabel && (
                <BlackFridayLandingLabel {...question.bfLabel} />
              )}
            </W3>
          </WrappedItem>
        </Wrapper>
        {!isAddsFlow ? (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-wrap: wrap;
`;

const Circle = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
`;

const CircleScience = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.8rem;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 0.975rem */
  letter-spacing: -0.018rem;
  color: #2a4b42;
  @media ${sxMobile} {
    font-size: 0.5rem;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 9.375rem;
  width: 100%;
  margin: 0 0 1rem;
`;

const IconContainer = styled.div`
  display: flex;
  max-width: 38.8rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  @media ${mobile} {
    margin-top: unset;
  }
`;

const W3 = styled.section`
  max-width: 720px;
  width: 100%;
  @media ${tablet} {
    margin-right: unset;
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div<{ hideFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light0};
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: 72px;
  @media ${tablet} {
    padding: 0 1rem;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  max-width: 90rem;
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light0};
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    padding: 0;
    flex-direction: column;
    align-items: unset;
    gap: 1.5rem;
  }
`;

const HeaderImg = styled(Logo)`
  height: 2.44rem;
  width: 13.9rem;
  @media ${tablet} {
    height: 1.63rem;
    width: 9.06rem;
  }
`;

const HeaderContainer = styled.header<{ top?: string }>`
  position: absolute;
  top: ${({ top = '0' }) => top};
  left: 3rem;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  margin: 0 auto;
  width: 100%;

  @media ${tablet} {
    position: static;
    top: auto;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0 1.06rem;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 10000%;
  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000% 0;

  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const SocialProof = styled.div<{
  des: string;
  mob: string;
  isFullHeight?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});
    height: 308px;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  @media ${tablet} {
    align-items: center;
    padding-bottom: 24px;
    justify-content: center;
  }
`;

const Title = styled(Text)`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.75rem;
  span {
    color: #ef71a0;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  color: #28293d;
  font-size: 1.125rem;
  font-weight: 400;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;
const ButtonsLabel = styled(Text)`
  color: #28293d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${mobile} {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: normal;
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;
  max-width: 624px;
  @media ${tablet} {
    text-align: left;
    text-align-last: left;
    padding-bottom: 1.5rem;
    display: flex;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  max-width: 10.3125rem;
  width: 100%;
  height: 3.5rem;
  margin-right: 1rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${tablet} {
    height: 3rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: #fff;

  border: 1px solid #4d4d4d;
  div p {
    text-align: center;
    color: #4d4d4d;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 1rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;
  margin-right: 0;
  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
  }
`;

const StyledHeaderText = styled(Text)`
  color: #436460;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;
