import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';

import { Route } from 'components';
import Success from 'pages';

export const Modals = () => (
  <Router>{Route(withPrefix('/:page/:page/success'), Success)}</Router>
);
