import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Text from 'components/Text';
import { OrderSummarySubItem } from 'types/checkout';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { floor } from 'lodash';

interface PaymentDialogOrderSummaryProps {
  selectedProducts: Product[] | null;
  subItems?: OrderSummarySubItem[];
}

const Container = styled.div`
  padding-top: 0.375rem;
  border-radius: 0.25rem;
`;

const OrderSummaryItemContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (!paddingBottom ? 0 : 0.75)}rem;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 0 0 1rem 0;
`;
const BilledPeriod = styled(Text)`
  color: #77788d;
`;

const FreeText = styled(Text)`
  color: #804da1;
  text-align: right;
  font-size: 0.875rem;
  font-family: 'Avenir', 'Open Sans';
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.014rem;
`;

const OrderSummaryItemContainerPrice = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 4px;
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProducts, subItems, ...props }) => {
  const { subscription_config } = useSelector((s: AppState) => s.user);

  const { isMobile } = useQuery();
  if (!selectedProducts) {
    return null;
  }

  const localisedProducts = selectedProducts?.map(product =>
    getLocalisedProduct(product),
  );

  let totalDisountPrice = 0;
  let totalDiscountPercentage = 0;
  let fullPrice = 0;

  for (let i = 0; i < localisedProducts.length; i++) {
    totalDisountPrice =
      totalDisountPrice + Number(localisedProducts[i]?.finalPrice);
  }
  for (let i = 0; i < localisedProducts.length; i++) {
    fullPrice = fullPrice + Number(localisedProducts[i].regularPrice);
  }

  const calculateOffPercentage = (totalPrice, discountedPrice) =>
    (100 - (discountedPrice * 100) / totalPrice).toFixed(0);

  return (
    <Container {...props}>
      {/* {localisedProducts?.map(product => (
        <>
          <OrderSummaryItemContainer paddingBottom>
            <Text type="bodyM600">
              {product?.months > 0 ? 'Assistant App ' : null}
              {product?.name}
            </Text>
            <Text type="bodyM600">
              {Number(product?.regularPrice) > 0 ? (
                `${product.currencyId} ${product?.regularPrice} `
              ) : (
                <FreeText>Free</FreeText>
              )}
            </Text>
          </OrderSummaryItemContainer>
        </>
      ))} */}

      {/* <Divider /> */}
      {/* <OrderSummaryItemContainer paddingBottom> */}
      {/* <Text type="bodyM600">
          Discount (-{calculateOffPercentage(fullPrice, totalDisountPrice)}%)
        </Text>
        <Text type="bodyM600" color="red80">
          {`-${localisedProducts[0].currencyId} ${floor(
            fullPrice - totalDisountPrice,
            2,
          )
            .toFixed(2)
            .padEnd(2, '0')} `}
        </Text>
      </OrderSummaryItemContainer> */}
      <OrderSummaryItemContainer>
        <Text type="body700">Total:</Text>
        <Text type="body700">
          {`${localisedProducts[0]?.currencyId} ${totalDisountPrice.toFixed(
            2,
          )} `}
        </Text>
      </OrderSummaryItemContainer>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
