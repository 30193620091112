import { Seo } from 'components';
import React, { useRef } from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { Quiz, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import theme from 'utils/theme';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import StartNowButton from '../pages/start/results/components/StartNowButton';
import LCFooter from 'components/LCFooter';
import Tracking from 'utils/tracking';
import { navigate } from 'gatsby';

enum QuestionType {
  Single = 'single',
  Multiple = 'multiple',
  MultipleImages = 'multiple_images',
  Encouragement = 'encouragement',
  Testimonial = 'testimonial',
  Info = 'info',
  Landing = 'landing',
  Landing2 = 'landing2',
  Image = 'image',
  Slider = 'slider',
  Scale = 'scale',
  Input = 'input',
  PreviousMultiple = 'previous_multiple',
  Insert = 'insert',
  Cost = 'cost',
}

const Home: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const questions = useSelector((state: AppState) => state.funnel.questions);
  const question = questions[0];
  const { isTablet } = useQuery();
  const landingRef = useRef(null);
  const { goToQuizStart } = useRouter();
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  const q = new URLSearchParams(location.search).get('q') ?? '1';

  const logEvent = (data: { category: string; label?: string }) => {
    Tracking.trackQuizQuestionAnswered({
      event: `Quiz question answered - ${quiz}`,
      category: `${q} - ${data.category}`,
      label: data.label || '',
    });
  };

  const handleLandingOptionSelect = (answer: QuizAnswer) => {
    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(answer.label),
    });
    delete answer.label;
    dispatch(updateQuizAnswers(answer));
    goToQuizStart();
  };

  return (
    <>
      <Seo />
      {/* {question?.type === QuestionType.Landing ? (
        <div ref={landingRef}> */}
      <Landing
        hideFooter
        question={question}
        onOptionSelect={handleLandingOptionSelect}
      />
      {/* </div>
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )} */}
      {/* <LCFooter disclaimerText={question?.disclaimerParagraphs || []} /> */}
    </>
  );
});

Home.displayName = 'Home';

export default Home;

const StyledButton = styled(StartNowButton)<{
  bgColor?: string;
  fontColor?: string;
}>`
  width: 100%;
  max-width: 21.4375rem;
  margin: 3rem auto 0rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  @media ${mobile} {
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;

const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
`;
