import React from 'react';
import { Helmet } from 'react-helmet-async';
import fav from '../../assets/images/favicon.ico';

const AppTags = () => (
  <Helmet>
    <link href={fav} id="favicon" rel="shortcut icon" type="image/x-icon" />
    <meta name="application-name" content="Welcome.baby" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta itemProp="url" content="https://welcome.baby" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta
      name="msapplication-TileImage"
      content="/favicons/favicon-128x128.png"
    />
    <meta
      name="msapplication-square150x150logo"
      content="/favicons/favicon-150x150.png"
    />
    <meta
      name="msapplication-square310x310logo"
      content="/favicons/favicon-310x310.png"
    />
    <meta
      id="description"
      name="description"
      content="Welcome Baby is the first personalized baby book for your milestones and memories. Create a story full of love & precious moments, and make your parenting journey magical!"
    />
  </Helmet>
);

const OGTags = () => (
  <Helmet>
    <meta id="og-image" property="og:image" content="/logo.png" />
    <meta id="og-title" property="og:title" content="Welcome.baby" />
    <meta
      id="og-image-secure"
      property="og:image:secure_url"
      content="/logo.png"
    />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Welcome.baby" />
    <meta property="og:image:width" content="800" />
    <meta property="og:image:height" content="800" />
    <meta
      id="og-description"
      property="og:description"
      content="Welcome Baby is the first personalized baby book for your milestones and memories. Create a story full of love & precious moments, and make your parenting journey magical!"
    />
  </Helmet>
);

const TwitterTags = () => (
  <Helmet>
    <meta id="twitter-image" name="twitter:image" content="/logo.png" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@welcome.baby" />
    <meta name="twitter:site" content="@welcome.baby" />
    <meta
      id="twitter-description"
      name="twitter:description"
      content="Welcome Baby is the first personalized baby book for your milestones and memories. Create a story full of love & precious moments, and make your parenting journey magical!"
    />
  </Helmet>
);

export const MetaTags = () => (
  <>
    <AppTags />
    <OGTags />
    <TwitterTags />
  </>
);
