import React, { FC } from 'react';
import styled from 'styled-components';

import theme from 'utils/theme';
import { Loader } from './Loader';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const InitialLoader: FC = () => {
  const isLoading = useSelector(
    (state: AppState) =>
      !(state.funnel && state.config && state.user && state.payment),
  );

  return (
    <>
      {isLoading ? (
        <Container>
          <Loader height={100} width={100} />
        </Container>
      ) : null}
    </>
  );
};
