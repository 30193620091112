import React, { useMemo } from 'react';
import type { FC } from 'react';
import PrimaryButton from 'components/PrimaryButton';
import type { Color } from 'utils/theme';

type ColorVariant = 'orange' | 'green' | 'dark';

interface StartQuizButtonProps {
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  children: string;
  colorVariant: ColorVariant;
}

const StartQuizButton: FC<StartQuizButtonProps> = ({
  onClick,
  active,
  children,
  colorVariant,
  disabled,
}) => {
  const buttonColorProps: Record<
    ColorVariant,
    { color: Color; colorActive: Color }
  > = useMemo(
    () => ({
      orange: {
        color: 'orange80',
        colorActive: 'orange100',
      },
      green: {
        color: 'green100',
        colorActive: 'greenDark',
      },
      dark: {
        color: 'dark80',
        colorActive: 'dark100',
      },
    }),
    [],
  );

  return (
    <PrimaryButton
      onClick={onClick}
      active={active}
      cornerVariant="round"
      minHeight={3.5}
      disabled={disabled}
      {...buttonColorProps[colorVariant]}
    >
      {children}
    </PrimaryButton>
  );
};

export default React.memo(StartQuizButton);
